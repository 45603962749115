<template>
  <b-modal
    id="modal-open-cash"
    hide-header-close
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    ok-title="Buka"
    size="lg"
    :title="addCash ? 'Tambah Kas' : 'Buka Kas Kecil'"
    class="custom__modal"
    @show="updateFormattedDate"
  >
    <b-row class="p-2">
      <b-col cols="12" md="6" v-if="!addCash">
        <b-form-group class="custom__form--input">
          <label for="v-name">Nama User</label>
          <b-form-input
            id="v-name"
            v-model="user.name"
            class="custom__input"
            type="text"
            placeholder="Nama"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" v-if="!addCash">
        <b-form-group class="custom__form--input">
          <label for="v-date">Waktu Buka Kas</label>
          <b-form-input
            id="v-date"
            v-model="date"
            class="custom__input"
            type="text"
            placeholder="Tanggal"
            @input="getRupiah"
            @change="getRupiah"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" v-if="addCash">
        <b-form-group class="custom__form--input">
          <label for="v-amount"
            >Jumlah Tambahan Cash <span class="text-danger">*</span></label
          >
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-amount"
              v-model="formPayload.additional_cashes[0].amount"
              class="custom__input"
              type="text"
              @input="getRupiah"
              @change="getRupiah"
              required
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" v-if="addCash">
        <b-form-group class="custom__form--input">
          <label for="v-description"
            >Deskripsi <span class="text-danger">*</span></label
          >
          <b-input-group>
            <b-form-input
              id="v-description"
              v-model="formPayload.additional_cashes[0].description"
              class="custom__input"
              rows="3"
              max-rows="4"
              required
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="!addCash">
        <b-form-group class="custom__form--input">
          <label for="v-amount">Jumlah Kas Awal</label>
          <b-input-group prepend="Rp">
            <b-form-input
              id="v-amount"
              v-model="formPayload.opening_cash"
              class="custom__input"
              type="text"
              @input="getRupiah"
              @change="getRupiah"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="button__group">
      <b-button class="button__cancel fw-bold-700 size13" @click="closeModal">
        Batal
      </b-button>
      <b-button
        class="button__save fw-bold-700 size13"
        @click="addCash ? addItem() : createItem()"
        :disabled="addCash && isDisabled"
      >
        {{ addCash ? "Tambah" : "Buka" }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BRow,
  BCol,
  BContainer,
  BTable,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";

import moment from "moment";

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  data() {
    const newData = new Date();
    const momentDate = moment(newData);
    const days = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
    ];
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    const formattedDate = `${days[momentDate.day()]}, ${momentDate.format(
      "DD"
    )} ${months[momentDate.month()]} ${momentDate.format("YYYY HH:mm")}`;

    return {
      moment,
      isLoading: false,
      user: JSON.parse(localStorage.getItem("userData")),
      date: formattedDate,
      formPayload: {
        opening_cash: 0,
        additional_cashes: [
          {
            amount: 0,
            description: "",
          },
        ],
      },
      addCash: false,
      detailResultList: {},
    };
  },
  props: {
    getData: {
      type: Function,
    },
    isAddCash: {
      type: Boolean,
    },
    detailResult: {
      type: Object,
    },
  },
  watch: {
    isAddCash(newValue, oldValue) {
      this.addCash = newValue;
    },
    detailResult(newValue, oldValue) {
      this.detailResultList = newValue;
    },
  },
  computed: {
    isDisabled() {
      const amount = this.formPayload.additional_cashes[0].amount;
      const description = this.formPayload.additional_cashes[0].description;
      return !amount || amount === 0 || !description;
    },
  },
  methods: {
    setRupiah() {
      const rupiahValue = this.formatRupiah(
        this.formPayload.additional_cash.toString()
      );
      this.formPayload.opening_cash = rupiahValue;
      this.formPayload.additional_cashes[0].amount = rupiahValue;
    },
    getRupiah() {
      const rupiah = document.getElementById("v-amount");
      rupiah.value = this.formatRupiah(rupiah.value);
      this.formPayload.opening_cash = rupiah.value;
      this.formPayload.additional_cashes[0].amount = rupiah.value;
    },
    formatRupiah(angka, prefix) {
      let number_string = angka.replace(/[^,\d-]/g, "").toString();
      let isNegative = number_string.startsWith("-");
      if (isNegative) {
        number_string = number_string.substring(1);
      }
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return (
        (isNegative ? "-" : "") + (prefix == undefined ? rupiah : rupiah || "")
      );
    },

    closeModal() {
      this.cleanUpForm();
      this.$bvModal.hide("modal-open-cash");
    },
    cleanUpForm() {
      this.formPayload.additional_cashes[0].amount = 0;
      this.formPayload.additional_cashes[0].description = "";
      this.formPayload.additional_cash = 0;
    },
    preparePayload() {
      const form = new FormData();

      if (this.addCash) {
        const additionalCash = this.formPayload.additional_cashes[0];
        form.append(
          "additional_cashes[0][amount]",
          parseFloat(this.preformatFloat(additionalCash.amount.toString()))
        );
        form.append(
          "additional_cashes[0][description]",
          additionalCash.description
        );
      } else {
        form.append(
          "opening_cash",
          parseFloat(
            this.preformatFloat(this.formPayload.opening_cash.toString())
          )
        );
      }

      return form;
    },
    createItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("pettyCash/postOpen", {
          query: "",
          payload: form,
        })
        .then(() => {
          this.isLoading = false;
          successNotification(this, "Success", "Kas Kecil berhasil dibuat");
          this.cleanUpForm();
          this.$bvModal.hide("modal-open-cash");
          this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    addItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("pettyCash/postAdd", {
          query: "",
          payload: form,
          uuid: this.detailResultList.uuid,
        })
        .then(() => {
          this.isLoading = false;
          successNotification(this, "Success", "Cash berhasil ditambahkan");
          this.cleanUpForm();
          this.$bvModal.hide("modal-open-cash");
          this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    updateFormattedDate() {
      const newDate = new Date();
      const momentDate = moment(newDate);
      const days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      this.date = `${days[momentDate.day()]}, ${momentDate.format("DD")} ${
        months[momentDate.month()]
      } ${momentDate.format("YYYY HH:mm")}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
.modal-content {
  border-radius: 16px;
  .modal-header {
    background-color: #fff !important;
    border-bottom: 1px solid #eee !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .modal-title {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .modal-body {
    padding: 0;
  }
}

.button__group {
  display: flex;
  width: 100%;

  .button__cancel {
    background-color: #ffffff !important;
    border-color: #fff !important;
    color: $dark !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 0px 16px;
    width: 100%;
    height: 72px;
    font-size: 16px;
    font-weight: 800;
  }

  .button__save {
    background-color: #45b6ab !important;
    border-color: #45b6ab !important;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 16px 0px;
    width: 100%;
    height: 72px;
    font-size: 16px;
    font-weight: 800;
  }
}
</style>
